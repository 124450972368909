import React from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Title, Grid } from "@UI";
import * as styles from "./partners.module.scss";
import {
  Tokemak,
  Lixir,
  BoringDao,
  Divergence,
  Idle,
  Flexa,
  Dfx,
  Sentnl,
  Pessimistic,
} from "@images/logos";

const Partners = () => {
  const data = [
    {
      url: "https://www.tokemak.xyz/",
      logo: <Tokemak />,
    },
    {
      url: "https://lixir.finance/",
      logo: <Lixir />,
    },
    {
      url: "https://www.boringdao.com/",
      logo: <BoringDao />,
    },
    {
      url: "https://www.divergence-protocol.com/",
      logo: <Divergence />,
    },
    {
      url: "https://idle.finance/",
      logo: <Idle />,
    },
    // {
    //   url: "https://flexa.network/",
    //   logo: <Flexa />,
    // },
    {
      url: "https://dfx.finance/",
      logo: <Dfx />,
    },
    {
      url: "https://sentnl.io/",
      logo: <Sentnl />,
    },
    {
      url: "https://pessimistic.io/",
      logo: <Pessimistic />,
    },
  ];
  return (
    <Container wrapper section isSection>
      <Title sm weightMd className={styles.title}>
        Partner with:
      </Title>
      <Grid>
        {data.map(({ logo, url }, i) => (
          <Fade key={i} triggerOnce fraction={0.4}>
            {url ? (
              <a href={url} className={styles.logoContainer}>
                {logo}
              </a>
            ) : (
              <span className={styles.logoContainer}>{logo}</span>
            )}
          </Fade>
        ))}
      </Grid>
    </Container>
  );
};

export default Partners;
