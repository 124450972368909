import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";
import { Container, Title } from "@UI";
import * as styles from "./roadmap.module.scss";

const Roadmap = () => {
  return (
    <Container wrapper section isSection>
      <div className={styles.textContainer}>
        <Fade triggerOnce>
          <Title lg>Roadmap</Title>
        </Fade>
      </div>
      <Container sectionTop className={styles.imageContainer}>
        <Fade triggerOnce>
          <StaticImage
            src="../../../assets/images/roadmap.svg"
            alt=""
            placeholder="blurred"
            layout="constrained"
            width={1280}
          />
        </Fade>
      </Container>
    </Container>
  );
};

export default Roadmap;
