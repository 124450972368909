import React from "react";
import { Container, Flex } from "@UI";
import { Twitter, Medium, Discord } from "@images/icons";
import * as styles from "./footer.module.scss";

const Footer = () => {
  const links = [
    {
      url: "https://medium.com/insuredao",
      icon: <Medium />,
    },
    {
      url: "https://twitter.com/insuredao",
      icon: <Twitter />,
    },
    {
      url: "https://discord.gg/8BA5f5rurq",
      icon: <Discord />,
    },
  ];
  return (
    <footer className={styles.footer}>
      <Container wrapper>
        <Flex className={styles.inner}>
          <p className={styles.text}>
            © 2021-{new Date().getFullYear()} InsureDAO. All Rights Reserved.
            <a
              className={styles.link}
              href={`/docs/InsureDAO-DISCLAIMERS-20220130.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Disclaimers
            </a>
          </p>
          <div className={styles.socialContainer}>
            {links.map(({ url, icon }) => (
              <a href={url} target="_blank" rel="noreferrer" key={url}>
                {icon}
              </a>
            ))}
          </div>
        </Flex>
      </Container>
    </footer>
  );
};
export default Footer;
