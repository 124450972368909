import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Fade } from "react-awesome-reveal";
import { Container, Title } from "@UI";
import { Twitter, LinkedIn, Github } from "@images/icons";
import * as styles from "./team.module.scss";

const Team = () => {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      allFile(filter: { sourceInstanceName: { eq: "avatars" } }) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const images = data.allFile.edges;

  const team = [
    {
      name: "Kohshi Shiba",
      role: "Founder / Developer",
      imageName: "kohshi-shiba",
      links: [
        {
          url: "https://twitter.com/KOHSHIBA",
          icon: <Twitter />,
        },
        {
          url: "https://linkedin.com/in/kohshiba",
          icon: <LinkedIn />,
        },
        {
          url: "https://github.com/kohshiba",
          icon: <Github />,
        },
      ],
    },
    {
      name: "Shun Oikawa",
      role: "Co-founder / Lead Developer",
      imageName: "shun-oikawa",
      links: [
        {
          url: "https://twitter.com/oishun1112",
          icon: <Twitter />,
        },
        {
          url: "https://github.com/oishun1112",
          icon: <Github />,
        },
      ],
    },
    {
      name: "Rubio Kishigami",
      role: "Co-founder / Business Development",
      imageName: "rubio-kishigami",
      links: [
        {
          url: "https://twitter.com/rubiokishi",
          icon: <Twitter />,
        },
        {
          url: "https://linkedin.com/in/rubio-kishigami-31b029160",
          icon: <LinkedIn />,
        },
      ],
    },
    {
      name: "Motoki Takahashi",
      role: "Co-founder / Marketing",
      imageName: "motoki-takahashi",
      links: [
        {
          url: "https://twitter.com/motoki304",
          icon: <Twitter />,
        },
        {
          url: "https://linkedin.com/in/motoki-takahashi-778225203",
          icon: <LinkedIn />,
        },
      ],
    },
  ];

  const team2 = [
    [
      {
        name: "Yuji Yamaguchi",
        role: "Frontend Developer",
        links: [
          {
            url: "https://twitter.com/yujiym",
            icon: <Twitter />,
          },
          {
            url: "https://github.com/yujiym",
            icon: <Github />,
          },
        ],
      },
      {
        name: "Hiroki Kitano (Hirory)",
        role: "Researcher",
        links: [
          {
            url: "https://twitter.com/Hirory_Chain",
            icon: <Twitter />,
          },
        ],
      },
    ],
    [
      {
        name: "Taishi",
        role: "Product Lead",
        links: [
          {
            url: "https://twitter.com/taishi_DeFi",
            icon: <Twitter />,
          },
          {
            url: "https://www.linkedin.com/in/taishi-sato-108a04156/",
            icon: <LinkedIn />,
          },
        ],
      },
      {
        name: "yamapyblack",
        role: "Sr. Smart Contracts Developer",
        links: [
          {
            url: "https://twitter.com/yamapyblack",
            icon: <Twitter />,
          },
        ],
      },
    ],
    [
      {
        name: "Ryota Yamaguchi",
        role: "Smart Contracts Developer",
        links: [
          {
            url: "https://twitter.com/maguroid",
            icon: <Twitter />,
          },
        ],
      },
    ],
  ];

  return (
    <Container wrapper sectionTop isSection className={styles.container}>
      <Title center lg className={styles.title}>
        Meet the team
      </Title>

      <div className={styles.teamContainer}>
        {team.map(({ name, role, imageName, links }) => {
          const imageMatch = images.find(
            ({ node: image }) => image.name === imageName
          );
          const image = getImage(imageMatch?.node);
          return (
            <div className={styles.profileContainer} key={name}>
              <Fade triggerOnce fraction={0.2}>
                <div>
                  <GatsbyImage image={image} alt={name} />
                  <p className={styles.name}>{name}</p>
                  <p className={styles.role}>{role}</p>
                  <div className={styles.socialContainer}>
                    {links.map(({ url, icon }) => (
                      <a href={url} key={url} target="_blank" rel="noreferrer">
                        {icon}
                      </a>
                    ))}
                  </div>
                </div>
              </Fade>
            </div>
          );
        })}
        {team2.map((lists, i) => (
          <div className={styles.profileContainer} key={i}>
            <Fade triggerOnce fraction={0.2}>
              {lists.map(({ name, role, links }) => (
                <div className={styles.profile2Container}>
                  <p className={styles.name}>{name}</p>
                  <p className={styles.role}>{role}</p>
                  <div className={styles.socialContainer}>
                    {links.map(({ url, icon }) => (
                      <a href={url} key={url} target="_blank" rel="noreferrer">
                        {icon}
                      </a>
                    ))}
                  </div>
                </div>
              ))}
            </Fade>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Team;
