import React from "react";
import { Fade } from "react-awesome-reveal";
import { Container, Title, Grid } from "@UI";
import * as styles from "./audit.module.scss";
import {
  Quantumstamp,
  Peckshield,
  Solidified,
  Code4rena,
  Immunefi,
} from "@images/logos";

const Audit = () => {
  const data = [
    {
      url: "https://quantstamp.com/",
      logo: <Quantumstamp />,
    },
    {
      url: "https://peckshield.com/",
      logo: <Peckshield />,
    },
    {
      url: "https://solidified.io/",
      logo: <Solidified />,
    },
    {
      url: "https://code4rena.com/contests/2022-01-insuredao-contest",
      logo: <Code4rena />,
    },
    {
      url: "https://immunefi.com/bounty/insuredao/",
      logo: <Immunefi />,
    },
  ];
  return (
    <Container wrapper section isSection>
      <Title sm weightMd className={styles.title}>
        Audit and Verified:
      </Title>
      <Grid>
        {data.map(({ logo, url }, i) => (
          <Fade key={i} triggerOnce fraction={0.4}>
            {url ? (
              <a href={url} className={styles.logoContainer}>
                {logo}
              </a>
            ) : (
              <span className={styles.logoContainer}>{logo}</span>
            )}
          </Fade>
        ))}
      </Grid>
    </Container>
  );
};

export default Audit;
