import React from "react";
import { Fade } from "react-awesome-reveal";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Title, Button } from "@UI";
import * as styles from "./hero.module.scss";

const Hero = () => {
  return (
    <section className={styles.container}>
      <div className={styles.background}>
        <StaticImage
          src="../../../assets/images/InsureDAO_hero.png"
          alt=""
          layout="fullWidth"
          quality={100}
          objectFit="cover"
          objectPosition="center bottom"
          style={{ position: "static" }}
          placeholder="blurred"
        />
      </div>

      <Container wrapperHero>
        <Fade triggerOnce>
          <Title black center margin tag="h1">
            The Decentralized Insurance Protocol
          </Title>
        </Fade>
        <Fade triggerOnce delay={300}>
          <p className={styles.subtitle}>
            InsureDAO is the Insurance platform for Smart-Contract vulnerability risks.
            The protocol are controlled by the $INSURE token stakers.
          </p>
        </Fade>
        <h4 className={styles.buttonContainerTitle}>Governance</h4>
        <div className={styles.buttonContainer}>
          <Button
            href="https://app.insuredao.fi/"
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <StaticImage
              src="../../../assets/images/chains/ethereum.svg"
              alt="Ethereum"
              width={28}
              height={28}
              style={{
                marginRight: "0.75rem",
              }}
            />
            Mainnet
          </Button>
        </div>
        <h4 className={styles.buttonContainerTitle}>Covers & Pools</h4>
          <div className={styles.buttonContainer}>
          <Button
            href="https://app.insuredao.fi/optimism"
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <StaticImage
              src="../../../assets/images/chains/optimism.svg"
              alt="Optiism"
              width={28}
              height={28}
              style={{
                marginRight: "0.75rem",
              }}
            />
            Optimism
          </Button>
          <Button
            href="https://app.insuredao.fi/arbitrum"
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <StaticImage
              src="../../../assets/images/chains/arbitrum.svg"
              alt="Arbitrum"
              width={28}
              height={28}
              style={{
                marginRight: "0.75rem",
              }}
            />
            Arbitrum
          </Button>
          <Button
            href="https://app.insuredao.fi/astar"
            style={{
              display: "flex",
              alignItems: "center",
              whiteSpace: "nowrap",
            }}
          >
            <StaticImage
              src="../../../assets/images/chains/astar.svg"
              alt="Astar"
              width={28}
              height={28}
              style={{
                marginRight: "0.75rem",
              }}
            />
            Astar Network
          </Button>
        </div>
        <h4 className={styles.buttonContainerTitle}>
          <a href="https://medium.com/insuredao/introducing-new-insurance-type-audited-code-coverage-7abcc3f8753c" target="_blank" rel="noreferrer">
            InsureDAO for Protocols (Coming soon) &#8599;
          </a>
        </h4>
      </Container>
    </section>
  );
};

export default Hero;
